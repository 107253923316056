import { render, staticRenderFns } from "./OrientacoesArquivosVisualizar.vue?vue&type=template&id=385ec568&scoped=true"
import script from "./OrientacoesArquivosVisualizar.vue?vue&type=script&lang=js"
export * from "./OrientacoesArquivosVisualizar.vue?vue&type=script&lang=js"
import style0 from "./OrientacoesArquivosVisualizar.vue?vue&type=style&index=0&id=385ec568&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "385ec568",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VBtn,VCard,VCarousel,VCarouselItem,VIcon,VImg,VLayout,VSheet,VSpacer,VToolbarTitle})
